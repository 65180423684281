import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/postLayout.tsx";
import { graphql } from 'gatsby';
import BannerImage from './images/banner.png';
import FlexboxDefaultLayoutImage from './images/flexbox-default-layout.png';
import JustifyContentImage from './images/justify-content.png';
import AlignItemsImage from './images/align-items.png';
import FlexWrapImage from './images/flex-wrap.png';
import FlexShrinkImage from './images/flex-shrink.png';
import FlexGrowImage from './images/flex-grow.png';
import FlexBasisImage from './images/flex-basis.png';
export const the8020OfFlexboxQuery = graphql`
  query The8020OfFlexboxQuery($pagePath: String!) {
    mdx(frontmatter: { path: { eq: $pagePath } }) {
      frontmatter {
        path
        title
        tags
        canonical_url
        tweet
        date(formatString: "MMMM DD, YYYY")
      }
      timeToRead
      excerpt
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  the8020OfFlexboxQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <img className="no-border" src={BannerImage} alt="Flexible Box Model" loading="eager" />
    <p>{`This article aims to explain the 20% of flexbox that you'll use 80% of the time.`}</p>
    <h2>{`What is Flexbox?`}</h2>
    <p>{`Flexbox is a way to describe how elements should be displayed inside another element. The outer element is called the `}<em parentName="p">{`flexbox container`}</em>{`, and the inner elements are known as `}<em parentName="p">{`flexbox items`}</em>{`.`}</p>
    <h2>{`How does it work?`}</h2>
    <p>{`A flexbox container is created by adding a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`display`}</code>{` CSS property with a value of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex`}</code>{` on it. Any direct child elements inside this container inherently become flex items. A flex item itself could also be a flex container for its child elements, but consider that as a completely isolated flexbox relationship. A flexbox relationship is always a single level deep between a parent element and one or more children.`}</p>
    <p>{`After declaring your flexbox container, all flex items will by default be placed horizontally next to one another, starting at the left and stretching to the height of the container.`}</p>
    <img className="no-border" src={FlexboxDefaultLayoutImage} alt="Default Flexbox Layout" loading="lazy" />
    <figcaption>The default Flexbox layout</figcaption>
    <h3>{`Flexbox Container properties`}</h3>
    <p>{`To dictate whether flex items should be laid out horizontally or vertically, you set the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-direction`}</code>{` property to either `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`row`}</code>{` (horizontal default) or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`column`}</code>{` (vertical). There are also reverse "row" and "column" options available that will lay items out horizontally, from right to left (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`row-reverse`}</code>{`) or vertically from bottom to top (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`column-reverse`}</code>{`), assuming you use a horizontal left to right writing mode.`}</p>
    <p>{`Assuming you use the default `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-direction: row`}</code>{` declaration. To specify how items should be placed on the horizontal axis (known as the `}<em parentName="p">{`main axis`}</em>{`), think about the justification options you normally have in a rich text editor, i.e. aligned left, aligned right or centered. In flexbox, you use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`justify-content`}</code>{` property on the container to do the same. The default is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`justify-content: flex-start`}</code>{` that aligns the items at the start of your main axis. Other possible values are `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-end`}</code>{`, your best friend `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`center`}</code>{`, and lesser-used `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`space-between`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`space-around`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`space-evenly`}</code>{`.`}</p>
    <img className="no-border" src={JustifyContentImage} alt="Flexbox Justify Content Examples" loading="lazy" />
    <figcaption>Justify Content Examples</figcaption>
    <p>{`To specify how flex items should be placed vertically in the container, on what is known as the cross axis, you use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`align-items`}</code>{` property. The available values are very similar to what you get for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`justify-content`}</code>{`, with a few exceptions: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-start`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-end`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`center`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`stretch`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`baseline`}</code>{`. By default flex items will `}<em parentName="p">{`stretch`}</em>{` the full height of the container. To place items at the top or bottom along the cross axis, use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-start`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-end`}</code>{` respectively. Traditionally, it has been quite hard to vertically centre items using only CSS, but with flexbox, you set `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`align-items: center`}</code>{` and call it a day. The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`baseline`}</code>{` option is worth knowing about, but you'll use less often. If you have neighbouring flex items containing different sized text and you want to align them based on the bottom of the text, use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`baseline`}</code>{`.`}</p>
    <img className="no-border" src={AlignItemsImage} alt="Flexbox Align Items Examples" loading="lazy" />
    <figcaption>Align Items Examples</figcaption>
    <blockquote>
      <p parentName="blockquote">{`Have you noticed that we've already catered for most layout requirements, and so far we didn't specify a single CSS property on any flex item? Everything has been on the flex container. Amazing.`}</p>
    </blockquote>
    <p>{`With that said, if you need to align items individually, you can set the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`align-self`}</code>{` property on an item. It takes all the available values that the container's `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`align-items`}</code>{` supports.`}</p>
    <p>{`It is worth mentioning again that all the examples so far assume a left-to-right writing mode and the default horizontal row flex-direction. When either of these change, the concepts remain the same, only your perspective changes. What I mean is that in a right-to-left writing mode using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-direction: column-reverse`}</code>{`, items will be justified along the main axis from bottom to top, but now aligned along the cross axis from right to left.`}</p>
    <p>{`What I've found is that it is easier to grasp the fundamentals first using the standard `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-direction: row`}</code>{`. After that, you can swap the flexbox axes around in your mind to visualize how reversed columns would work.`}</p>
    <p>{`The last flexbox container property worth talking about is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-wrap`}</code>{`. By default flex items will not wrap to a new row when they run out of horizontal space. They rather get very cosy and squash together as far as possible until the CSS layout engine decides that's enough.`}</p>
    <p>{`To tell these items rather wrap to a new row, set `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-wrap: wrap`}</code>{`. As I mentioned, the default is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-wrap: nowrap`}</code>{`.`}</p>
    <img className="no-border" src={FlexWrapImage} alt="Flex Wrap Examples" loading="lazy" />
    <figcaption>Flex Wrap Examples</figcaption>
    <p>{`That wraps up all the container properties that we're going to talk about. The rest of the properties are all set for individual flex items.`}</p>
    <h3>{`Flexbox Item Properties`}</h3>
    <p>{`The cosy behaviour of flex items to squash together can be overridden with the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-shrink`}</code>{` property. This property dictates in what proportion an item should shrink inside the container when space becomes scarce. The default is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-shrink: 1`}</code>{`, which means all items shrink equally. If one of the items is set to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-shrink: 2`}</code>{` while the rest keep a value of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{`, this item will shrink twice as fast. It is important to note that the value is a unitless value that represents the shrinkage proportion and not something like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`10px`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`50%`}</code>{`.`}</p>
    <img className="no-border" src={FlexShrinkImage} alt="Flex Shrink Example" loading="lazy" />
    <figcaption>Flex Shrink Example</figcaption>
    <p>{`In contrast to flex items' cosy shrink behaviour, they tend to be slightly shy by nature and will not "occupy all spaces" if any free space is available. In more official terms, all flex items have a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-grow`}</code>{` value of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{` set, which restricts it from growing. Just as with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-shrink`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-grow`}</code>{` is a unitless value that dictates in which proportion an item should grow within a container if any free space is available. It is important to note that if you have two flex items, one with a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-grow`}</code>{` value of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` and the other of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`2`}</code>{`, that does not mean the one item will be twice as big as the other. It means, from all the available space (if any), the one will receive two portions and the other, one portion.`}</p>
    <img className="no-border" src={FlexGrowImage} alt="Flex Grow Example" loading="lazy" />
    <figcaption>Flex Grow Example</figcaption>
    <p>{`The final flex item property we'll cover is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-basis`}</code>{`. You can think of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-basis`}</code>{` as the width of an item along the main axis. This property needs to be set with any of the standard CSS units, for example `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`px`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`rem`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`%`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vw`}</code>{`, etc. The default is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-basis: auto`}</code>{` which means the item takes the width of the content it contains, similar to a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`display: inline-block`}</code>{` element.`}</p>
    <p>{`A caveat to this rule is when a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`width`}</code>{` is specified, in which case the width would trump the default `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-basis: auto`}</code>{`. Another interesting thing to be aware of is that if you have a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`min-width`}</code>{` specified along with a non-default `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flex-basis`}</code>{`, the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`min-width`}</code>{` will always take precedence.`}</p>
    <img className="no-border" src={FlexBasisImage} alt="Flex Basis Example" loading="lazy" />
    <figcaption>Flex Basis Example</figcaption>
    <p>{`Of course, there is a lot more to flexbox, like shorthand syntax for some of the properties we looked at. Feel free to `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout"
      }}>{`dig deeper`}</a>{` once you're comfortable with these.`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      